/*
This css file is used for swiper classes which
are built into the swiper library
 */

/* Needed to fix issue with firefox double arrows appearing */
.swiper-button-next::after,
.swiper-button-prev::after {
  content: '' !important;
}

/* Override the pointer-events: none so that people won't accidentally click zoom on the image */
.swiper-button-disabled {
  pointer-events: auto !important;
}

.thumbs-wrapper .swiper-slide {
  cursor: pointer;
  width: auto;
  height: auto;
}

.magnifier {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.magnifier > div {
  display: flex;
  align-items: center;
  cursor: crosshair !important;
  height: 100%;
  width: 100%;
}

.magnifier > div > img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

/* For the image being magnified */
.magnifier > div > div > img {
  max-width: unset;
}

.thumbs-wrapper .swiper-slide img {
  height: 100% !important;
  object-fit: cover;
  object-position: center;

  @media (--viewportLarge) {
    height: auto;
  }
}

.carouselImagesWrapper .swiper-wrapper {
  display: flex;
  align-items: center;
}

.thumbs-wrapper .swiper-slide-thumb-active {
  border: 3px solid var(--marketplaceColor);
  box-sizing: border-box;
}

.thumbs-wrapper .swiper-wrapper .swiper-slide-visible:last-child {
  margin-right: 0 !important;
}

.thumbs-wrapper .swiper-wrapper:has(> :last-child:nth-child(-n + 4)) {
  justify-content: center;

  @media (--viewportLarge) {
    justify-content: start;
  }
}

.carouselImagesWrapper .swiper-wrapper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;

  @media (--viewportMedium) {
    height: 100%;
  }
}

.thumbs-wrapper .swiper-wrapper img {
  width: 100%;
  height: 100%;
}
